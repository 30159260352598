<template>
  <base-section
    id="affiliates"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading
        title="Who's using Lavvira?"
        :divider="false"
        style="color: #234E79;"
      />

      <v-slide-group>
        <template v-for="(l, index) in logos">
          <v-slide-item
            :key="index"
            class="align-self-center my-2"
          >
            <div class="mx-8">
              <base-img
                v-if="l._name === 'Lawbach'"
                :src="l.src"
                contain
                height="130"
                width="210"
                class="mt-1"
              />
              <base-img
                v-else
                :src="l.src"
                color="grey"
                contain
                height="100"
                width="180"
                :class="l._name === 'Progressive' ? 'invert' : ''"
              />
            </div>
          </v-slide-item>

          <!-- <v-responsive
            v-if="n < 6"
            :key="`divider-${index}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive> -->
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
    computed: {
      logos () {
        return [
          {
            _name: 'Lawbach',
            src: require('@/assets/lawbach_header_logo.png'),
          },
          // {
          //   _name: 'Progressive',
          //   src: require('@/assets/progressive-lawyers-main.svg'),
          // },
        ]
      },
    },
  }
</script>

<style scoped>
.invert {
  filter: invert(0.70);
}
.invert-lawbach {
  filter: invert(0.15);
}
</style>
